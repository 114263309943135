.sincerity-compliance-page {
  width: 100% !important;
}
.sincerity-compliance-page .main-container-width {
  width: 1200px;
  margin: 0 auto;
}
.sincerity-compliance-page .main-container {
  width: 100%;
  height: 831px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
     -moz-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 80px;
}
.sincerity-compliance-page .column-title {
  text-align: center;
  padding-top: 69px;
}
.sincerity-compliance-page .column-title .column-title-img {
  width: auto;
  height: 46px;
}
.sincerity-compliance-page .column-title img {
  width: auto;
  height: 104px;
}
.sincerity-compliance-page .main-container-content {
  width: 1200px;
  height: 470px;
  margin: 0 auto;
  background: #ffffff;
  -webkit-box-shadow: 0px 16px 48px 0px rgba(252, 222, 212, 0.42);
          box-shadow: 0px 16px 48px 0px rgba(252, 222, 212, 0.42);
  margin-top: 28px;
  -webkit-border-radius: 20px;
          border-radius: 20px;
  position: relative;
}
.sincerity-compliance-page .main-container-content .main-container-line-horizontal {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 178px;
  width: 1040px;
  height: 1px;
  border-bottom: 1px dashed #EEEEEE;
}
.sincerity-compliance-page .main-container-content .main-container-line-vertical {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  top: 40px;
  width: 1px;
  height: 276px;
  border-right: 1px dashed #EEEEEE;
}
.sincerity-compliance-page .main-container-content .content-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 60px 160px 20px 160px;
}
.sincerity-compliance-page .main-container-content .content-flex .content-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
     -moz-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  text-align: center;
}
.sincerity-compliance-page .main-container-content .content-flex .content-item .content-item-title {
  font-weight: bold;
  font-size: 20px;
  color: #1a1a1a;
  line-height: 30px;
}
.sincerity-compliance-page .main-container-content .content-flex .content-item .content-item-dsc {
  width: 360px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 24px;
  color: #4d4d4d;
}
.sincerity-compliance-page .main-container-content .content-bottom {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
  bottom: 2px;
  background: url('https://img.qlchat.com/qlLive/activity/image/VUIYBLEY-85Y2-BALN-1740049922053-H92YWPNWB3KD.png') no-repeat;
  -webkit-background-size: 100% auto;
          background-size: 100% auto;
  width: 1196px;
  height: 112px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
     -moz-box-orient: vertical;
     -moz-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
     -moz-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 200px;
}
.sincerity-compliance-page .main-container-content .content-bottom .content-bottom-item {
  padding-left: 30px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
  color: #1a1a1a;
}
.sincerity-compliance-page .main-container-content .content-bottom .content-bottom-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background: url('https://img.qlchat.com/qlLive/activity/image/8BKC6YAR-JHEB-RO88-1740037480128-HI8DZOA6CYS7.png') no-repeat;
  -webkit-background-size: 100% auto;
          background-size: 100% auto;
}
