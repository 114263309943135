.sincerity-compliance-ql-edu-container {
  height: 100%;
  background: #fff;
  overflow-y: auto;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}

.sincerity-compliance-ql-edu-container .fadeInUp {
  -webkit-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
  -moz-animation: fadeInUp 0.3s ease-in-out 0s 1 both;
  animation: fadeInUp 0.3s ease-in-out 0s 1 both;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0.426667rem, 0);
    -moz-transform: translate3d(0, 0.426667rem, 0);
    transform: translate3d(0, 0.426667rem, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    transform: none;
  }
}

.sincerity-compliance-ql-edu-container .main-container {
  background-repeat: no-repeat;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  padding: 2.346667rem 0.426667rem 1.173333rem;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-title {
  margin-bottom: 0.426667rem;
  text-align: center;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-title img {
  height: 0.733333rem;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content {
  width: 9.146667rem;
  height: 10.8rem;
  margin: 0 auto;
  background: #ffffff;
  -webkit-box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  box-shadow: 0 0.213333rem 0.64rem 0 rgba(252, 222, 212, 0.42);
  -webkit-border-radius: 0.426667rem;
  border-radius: 0.426667rem;
  position: relative;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .main-container-line-horizontal {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  top: 4.053333rem;
  width: 8.293333rem;
  height: 0.013333rem;
  border-bottom: 0.013333rem dashed #eeeeee;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .main-container-line-vertical {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%) rotate(-90deg);
  -moz-transform: translateX(-50%) rotate(-90deg);
  transform: translateX(-50%) rotate(-90deg);
  top: 4.053333rem;
  width: 7.253333rem;
  height: 0.013333rem;
  border-bottom: 0.013333rem dashed #eeeeee;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0.853333rem 0 0 0;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-flex .content-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1;
  -moz-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  text-align: center;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-flex .content-item .content-item-title {
  font-weight: bold;
  font-size: 0.4rem;
  color: #1a1a1a;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-flex .content-item .content-item-dsc {
  width: 3.8rem;
  margin: 0.106667rem auto 0;
  font-size: 0.373333rem;
  line-height: 0.586667rem;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-bottom {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0.026667rem;
  background: url('https://img.qlchat.com/qlLive/activity/image/Q3A3AO7Z-VXU9-2W3Y-1740050289364-DTGW76OU6LCN.png') no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  width: 9.093333rem;
  height: 2.666667rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0.426667rem;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-bottom .content-bottom-item {
  padding-left: 0.533333rem;
  position: relative;
  font-size: 0.346667rem;
  font-weight: bold;
  margin: 0.133333rem 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 0.466667rem;
  color: #1a1a1a;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-bottom .content-bottom-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0.426667rem;
  height: 0.426667rem;
  background: url('https://img.qlchat.com/qlLive/activity/image/RXJKGPYT-WX3P-3MLF-1740043957510-FTI39XFYQV67.png') no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.sincerity-compliance-ql-edu-container .main-container .main-container-content .content-bottom .content-bottom-item .content-bottom-item-right {
  width: 6rem;
}